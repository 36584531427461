<template>
    <v-card>
        <v-card-title class="primary white--text">
            <v-icon color="white">fas fa-stamp</v-icon>
            <h6 class="ml-3 text-h6">Quantos itens foram enviados para gravação?</h6>
        </v-card-title>
        <v-card-text class="mt-7">
            <div class="mb-7 d-flex justify-space-between">
                <span class="text-subtitle-2 px-4 pb-4">Qtd. Total: {{ item.alt_qtty ? item.alt_qtty : item.default_qtty }}</span>
                <span class="text-subtitle-2 px-4 pb-4">Enviados para gravação: {{ item.printed_qtty }}</span>
                <span class="text-subtitle-2 px-4 pb-4">Qtd. Restante: {{ rest_qtty }}</span>
            </div>
            <v-text-field
                v-model.number="rest_qtty"
                label="Quantidade"
                v-mask="['########']"
                :error-messages="errMsg"
                rounded
                outlined
            />
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn @click="$emit('closeDialog')" color="primary" rounded outlined>Cancelar</v-btn>
            <v-btn @click="save" :disabled="to_print_qtty == 0" color="primary" rounded>Salvar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data(){
            return {
                errMsg: null,
                to_print_qtty: null
            }
        },
        computed: {
            rest_qtty: {
                get: function(){
                    if(this.item){
                        return this.item.alt_qtty ? (this.item.alt_qtty - this.item.printed_qtty) : (this.item.default_qtty - this.item.printed_qtty)
                    }
                    return ''
                },
                set: function(newvalue){
                    this.to_print_qtty = newvalue
                }
            }
        },
        methods: {
            save(){
                if(this.item.alt_qtty){
                    if(this.to_print_qtty > this.item.alt_qtty){
                        this.errMsg = `Quantidade excede o pedido. Máx: ${this.item.alt_qtty}`
                        return
                    }
                }else if(this.to_print_qtty > this.item.default_qtty){
                    this.errMsg = `Quantidade excede o pedido. Máx: ${this.item.default_qtty}`
                    return
                    
                }else if(this.to_print_qtty > this.rest_qtty){
                    this.errMsg = `Quantidade excede o faltante. Faltam: ${this.rest_qtty}`
                    return

                }
                
                let payload = {
                    id: this.item.id,
                    printed_qtty: this.item.printed_qtty + this.to_print_qtty,
                    order_id: this.item.order_id
                }

                this.$api.patch('orders/products/print', payload)
                    .then(res => {
                        this.item.order_product_status = res.data.product.order_product_status
                        this.item.alt_qtty = res.data.product.alt_qtty
                        this.item.default_qtty = res.data.product.default_qtty
                        this.item.printed_qtty = res.data.product.printed_qtty
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.$emit('closeDialog')
                    })
            },
        },
        mounted(){
            this.to_print_qtty = this.rest_qtty
        }
    }
</script>